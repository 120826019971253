$(document).ready(function() {

    $("#midiv").click(function() {
        var clase = $("#midiv").attr("class");
        if (clase == "") {
            //alert("existe clase animando");
            //la quitamos y agregamos NORMAL
            $("#midiv").attr("class", "animated backInDown");

        } else {
            //alert("CLASE NORMAL");
            //agregamos ANIMACION
            $("#midiv").attr("class", "");

        }
    });
    $("#watchme").click(function() {
        $("#watchme").addClass("animated flash");
    });
    $("#watchme").on("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function() {

        $(this).removeClass("animated flash");

    });

/*
    $("#enchufewifi").hover(function() {
        $("#enchufewifi").addClass("animated zoomIn");
    });
    $("#enchufewifi").on("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function() {
        $(this).removeClass("animated zoomIn");
    });*/

    
    


});





/*var animationEnd = "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";

$("#watchme").addClass("animated flash").one(animationEnd, function() {
    $(this).removeClass("animated flash");
});*/


